.input {

  &-wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    cursor: pointer;
  }

  &-label {
    font-weight: 700;
    font-size: rem(20);
    line-height: rem(24);
    margin-bottom: em(18);
    text-transform: uppercase;
    color: #8B9AC1;
    cursor: pointer;
  }

  &-control {
    width: 100%;
    padding: em(18) em(16) em(18) em(24);
    background: #222938;
    border: initial;
    color: #FFF;

    &_number {
      appearance: none;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.form-group + .form-group {
  margin-top: em(20);
}

.form-group {

  .input-label {
    font-weight: 400;
    font-size: rem(20);
    line-height: rem(24);
    margin-bottom: em(4);
    color: #FFF;
  }

  &_centered {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
}

.btn {
  cursor: pointer;
}

.btn-control {
  position: relative;
  width: 100%;
  background: linear-gradient(90deg, #FFC600 0%, #FF4D00 100%);
  border: initial;
  border-radius: em(2);
  padding: em(20) 0;
  font-weight: 700;
  font-size: rem(25);
  line-height: rem(30);
  color: #FFF;
  text-transform: uppercase;
  transition: .5s all;

  &:disabled {
    cursor: not-allowed;
    background: linear-gradient(90deg, #3A496F 0%, #2C3A5E 100%);
    color: #657396;
  }

  &:not(:disabled) {
    box-shadow: em(1) em(20) em(47) rgba(255, 77, 0, 0.46);
  }

  &:not(:disabled):hover {

    .btn-hover {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
}

.btn-hover {
  content: "";
  position: absolute;
  width: em(14);
  height: em(14);
  transition: all .5s ease;
  opacity: 0;

  &_top {

    &-left {
      top: em(-9);
      left: em(-9);
      border-left: 3px solid #FFC600;
      border-top: 3px solid #FFC600;
      transform: translate(50%, 50%);
    }

    &-right {
      top: em(-9);
      right: em(-9);
      border-right: 3px solid #FF4D00;
      border-top: 3px solid #FF4D00;
      transform: translate(-50%, 50%);
    }
  }

  &_bottom {

    &-left {
      bottom: em(-9);
      left: em(-9);
      border-left: 3px solid #FFC600;
      border-bottom: 3px solid #FFC600;
      transform: translate(50%, -50%);
    }
    &-right {
      bottom: em(-9);
      right: em(-9);
      border-right: 3px solid #FF4D00;
      border-bottom: 3px solid #FF4D00;
      transform: translate(-50%, -50%);
    }
  }
}

.form-error {
  width: 100%;
  font-weight: 400;
  font-size: rem(20);
  line-height: rem(24);
  text-align: center;
  color: #FFC671;
  min-height: em(22);
}

.mt-18 {
  margin-top: em(18);
}

.input-container {
  width: 100%;
  position: relative;
}

.input-ruble {
  position: absolute;
  right: rem(16);
  top: 50%;
  transform: translateY(-50%);
  font-size: rem(27);
  line-height: rem(32);
  color: #657396;
}

.sum-amount {
  margin-top: em(17);
  text-align: center;
  font-size: rem(18);
  line-height: rem(21);
  color: #657396;

  span {
    color: #FFF;
  }
}

.form-group_payments {
  margin-top: em(44);
}

.payments {

  &-root {
    width: 100%;
    height: em(55);
    background: #222938;
  }

  &-container {
    padding: em(17) em(26);
  }
}

.payments-terms {
  margin-top: em(41) !important;

  span {
    font-size: rem(17);
    line-height: rem(20);
    color: #FFF;
  }

  .checkbox {
    width: rem(19);
    height: rem(19);

    input::after {
      width: rem(15);
      height: rem(15);
      background-size: contain;
    }
  }
}

.donate-error {
  margin-top: em(22);
}
