.checkbox {
  position: relative;
  display: flex;
  width: em(34);
  height: em(34);
  background: #1B202C;
  box-shadow: inset 0 em(4) em(4) rgba(0, 0, 0, 0.25);
  border-radius: em(2);
  cursor: pointer;

  input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    width: 0;
    cursor: pointer;
    appearance: none;

    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: em(21);
      height: em(21);
      border-radius: em(2);
      background: url('/assets/images/icons/checkbox.svg') no-repeat center center;
      transition: .5s all;
      opacity: 0;
    }
  }

  input:checked:after {
    opacity: 1;
  }
}
