@import "../../../../assets/css/helpers/functions";

.payment-method {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: rem(8);
  transition: .3s all;
  cursor: pointer;
  overflow: hidden;
  $paymentMethodRoot: &;

  &__alert {
    position: absolute;
    bottom: 0;
    font-size: rem(11);
    color: #fff;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(189, 203, 240, 0.04);
    backdrop-filter: blur(39px);
    transition: 0.3s all;
  }

  &_disabled {
    pointer-events: none;
    cursor: default;

    #{$paymentMethodRoot}__bg,
    img {
      opacity: 0.5;
    }
  }

  &__warning {
    position: relative;
    z-index: 1;
    margin-top: rem(10);
    font-size: rem(12);
    color: #fff;
  }
}

.payment-method:not(.payment-method_disabled):hover {
  .payment-method__bg {
    opacity: 0.5;
  }
}
