@import '../../../../assets/css/helpers/functions';

.donate-game {
  margin-top: rem(131);

  .donate-confirm {
    max-width: em(606 + 407);
  }

  .donate-confirm__payments {
    max-width: em(606);
    min-height: 29.875rem;
  }

  .donate-payments__container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
