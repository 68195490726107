@import './helpers/functions';
@import './base/fonts';
@import './base/icons';
@import './base/transitions';
@import './vendor/smart-grid';
@include reset();

$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-color: #fff;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
    cursor: default;
}

a {
    text-decoration: none;
}

:root {

    @include from(370px) {
        font-size: 1em;
    }
    font-size: calc(1vw + 0.6em);
    outline: none;
    box-sizing: border-box;
}

*, ::before, ::after {
    outline: inherit;
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
    overflow-x: hidden;
}

html {
    font-family: "Gotham",
    "Source Sans Pro",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Helvetica Neue",
    Arial,
    sans-serif !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    background: url('/assets/images/bg.jpg') #222E4B no-repeat top center / cover;
    //background: #222E4B;
}

.container {
    @include container();
    @include sm(max-width, rem(510));
    @include md(max-width, rem(690));
    @include lg(max-width, rem(930));
    @include xl(max-width, rem(1110));
    padding-bottom: em(300);
}

.page-container {
    position: relative;
    width: 100%;
    max-width: em(1920);
    min-height: 100vh;
    margin: 0 auto;
}

.login-title {
    margin-top: rem(140);

    &::before {
        left: 50%;
        transform: translateX(-50%);
    }
}

.login-form {
    width: 100%;
    max-width: em(581);
    margin: em(41) auto 0 auto;

    &__wrapper {
        width: 100%;
        max-width: em(373);
        margin: 0 auto;
    }

    &__btn-group {
        margin-top: em(22) !important;
    }
}

@import './modules/modules';

.home {

    &__scroll-down {
        @include from(1200px) {
            display: flex;
            margin-left: rem(230);
        }
        @include from(1400px) {
            margin-left: rem(430);
        }
        display: none;
        margin-top: rem(71);
        margin-left: rem(460);
    }

    &-container {
        position: relative;
    }

    &-content {
        @include from(587px) {
            padding-top: em(219);
        }
        @include from(1200px) {
            padding-top: em(546);
        }
        position: relative;
        padding-top: em(193);
    }

    &-title {
        @include from(587px) {
            font-size: rem(50);
            line-height: rem(60);
        }
        position: relative;
        z-index: 3;
        font-size: rem(36);
        line-height: rem(60);
        font-weight: 300;
        text-transform: uppercase;
        color: #fff;

        strong {
            font-weight: 900;
        }
    }

    &-subtitle {
        @include from(587px) {
            font-size: rem(40);
            line-height: rem(48);
            margin-top: em(7);
        }
        position: relative;
        z-index: 3;
        font-size: rem(24);
        line-height: rem(32);
        color: #fff;
    }

    &-role {
        @include from(1200px) {
            top: em(124);
            right: em(-10) !important;
            width: em(886) !important;
            height: em(1028);
            max-width: initial;
        }
        position: absolute;
        pointer-events: none;
        z-index: 2;
        top: em(72);
        right: -147px;
        width: calc(100% + 4.2em);
        max-width: em(592);
    }

    &-el {
        @include from(1200px) {
            width: em(995);
            height: em(1120);
            right: 0;
            top: 0;
            max-width: initial;
        }
        position: absolute;
        z-index: 1;
        pointer-events: none;
        top: -7.4375rem;
        right: -20.5875em;
        width: calc(100% + 15em);
        max-width: em(765);
    }

    &-outline {
        @include from(1200px) {
            top: em(354);
        }
        position: absolute;
        z-index: 1;
        top: em(30);
        left: 0;
        width: rem(1754);
        height: rem(205);
        background: url('../images/home/gta5rp.png') no-repeat left;
    }
}

.social-nav {
    @include from(1200px) {
        position: absolute;
        z-index: 10;
        margin-top: initial;
        top: rem(441);
        right: rem(16);
    }
    @include from(1820px) {
        right: rem(100);
    }
    margin-top: em(19);

    &__items {
        @include from(587px) {
            flex-flow: row nowrap;
        }
        @include from(1200px) {
            flex-flow: column nowrap;
        }
        display: flex;
        flex-flow: column nowrap;
    }

    &__item {
        @include from(587px) {
            margin-right: em(24);
        }
        @include from(1200px) {
            margin-right: initial;
        }
        margin-top: em(16);
    }

    &__link {
        @include from(587px) {
            width: em(50);
            height: em(50);
        }
        display: inline-flex;
        width: em(36);
        height: em(36);

        & > * {
            display: inline-flex;
            width: 100%;
            height: 100%;
            background: #fff;
            transition: .5s all;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}

@keyframes animateLine {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.home-social__action {
    max-width: em(555);
    margin: em(140) auto 0 auto;
}

.login-el {
    @include from(800px) {
        right: -25em;
    }
    @include from(1220px) {
        right: -270px;
        top: -40px;
    }
    position: absolute;
    z-index: -1;
    pointer-events: none;
    top: 0;
    right: -37.5875em;
    height: 100%;
    width: rem(995);
    background: url('/assets/images/home/el.svg') no-repeat top center / cover;
}

.login-roles {
    @include from(1220px) {
        display: block;
    }
    display: none;
    position: absolute;
    z-index: -1;
    pointer-events: none;
    right: 0;
    bottom: 0;
    width: rem(672);
    height: rem(910);
    background: url("../images/login-roles.png") no-repeat bottom right / cover;
}

.mt-55 {
    margin-top: rem(55) !important;
}

.donate-main {

    &__title {
        text-align: center;
        font-weight: 700;
        font-size: rem(25);
        line-height: rem(30);
        text-transform: uppercase;
        color: #FFF;
    }

    &__root {
        position: relative;
        z-index: 10;
        padding: em(37) 0 em(49) 0;
        background: rgba(189, 203, 240, 0.1);
        backdrop-filter: blur(39px);
    }

    &__form {
        width: 100%;
        max-width: em(346);
        margin: 0 auto;

        .input-label {
            font-weight: 700;
            font-size: rem(16);
            line-height: rem(19);
            margin-bottom: em(8);
            color: #8B9AC1;
        }

        .input-control {
            padding: rem(15) 0 rem(12) rem(17);
        }
    }
}

.guest-donate,
.user-donate {
    .btn-control {
        font-size: rem(17);
        line-height: rem(20);
        padding: rem(16) rem(16);
    }
}

.user-donate {
    .donate-main__root {
        padding-left: rem(16);
        padding-right: rem(16);
    }
}

.donate__title {
    @include from(973px) {
        margin-top: rem(90);
        font-size: rem(40);
        line-height: rem(48);
    }
    margin-top: rem(60);
    margin-bottom: rem(51);
    text-align: center;
    text-transform: initial;

    &::before {
        left: 50%;
        transform: translateX(-50%);
        top: rem(-58);
        width: rem(1212);
        height: rem(139);
        text-transform: capitalize !important;
        font-size: rem(100);
        line-height: rem(120);
    }
}

.servers {

    &__title {
        position: relative;
        @include from(973px) {
            margin-top: 5.625rem !important;
            font-size: rem(50);
            line-height: rem(60);
            text-align: left;

            &::before {
                left: 0;
                transform: initial;
            }
        }
        margin-top: rem(60);
        font-weight: 900;
        font-size: rem(40);
        line-height: rem(60);
        text-align: center;
        text-transform: uppercase;

        &::before {
            content: ' ';
            position: absolute;
            top: rem(-100);
            left: 50%;
            transform: translateX(-50%);
            width: rem(1754);
            height: rem(205);
            background: url('/assets/images/select-server.png') no-repeat left center;
        }

        @include from(973px) {
            margin-top: rem(180);
            text-align: left;

            &::before {
                left: 0;
                transform: initial;
            }
        }
    }
}

.state-pending__preloader {
    width: 100%;
    max-width: rem(500);
    height: rem(500);
    margin: 0 auto;
}

.preloader {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: url('../../assets/images/preloader.svg') no-repeat top center / cover;
}

.ttu {
    text-transform: uppercase;
}

.input-control:disabled {
    background: #303950;
}

.payment-status__title {
    margin-top: rem(259);

    &::before {
        top: rem(-80);
        left: 50%;
        transform: translateX(-50%);
    }
}

.payment-status__text {
    font-weight: 400;
    font-size: rem(25);
    line-height: rem(30);
    color: #FFF;
    text-align: center;
    margin-top: rem(15);
}

.payment-status__btn {
    margin: rem(91) auto 0 auto;
    max-width: rem(351);
}

.roles__empty {
    font-size: rem(16);
    color: #FFF;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden scroll;
    z-index: 20;

    &-root {
        width: 100%;
        max-width: rem(761);
        margin: rem(192) auto rem(192) auto;
    }

    &__container {
        position: relative;
        background: #37415A;
        backdrop-filter: blur(180px);
        padding: em(22) em(98) em(64) em(98);
    }

    &__title {

    }

    &__control {
        position: absolute;
        top: rem(20);
        right: rem(19);
        cursor: pointer;
    }
}

.feedback-form {
    margin-top: em(60);
}

.ff-group {
    margin-top: rem(31);
}

.feedback-form__submit {
    font-weight: 700;
    font-size: rem(20);
    line-height: rem(24);
    color: #FFF;
    padding: rem(20) 0;
}

.textarea-label {
    display: inline-flex;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1.125em;
    text-transform: uppercase;
    color: #8B9AC1;
    cursor: pointer;
}

.textarea-control {
    width: 100%;
    padding: 1.125em 1em 1.125em 1.5em;
    background: #222938;
    border: initial;
    min-height: rem(305);
    color: #ffffff;
}

.html_locked {
    overflow: hidden;
}

.donate-confirm {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  justify-content: center;
    width: 100%;
    max-width: rem(1136);
    height: auto;
    min-height: rem(478);
    margin: 0 auto;

    &__info {
        @include from(895px) {
            max-width: rem(407);
        }
        width: 100%;
        height: auto;
        min-height: rem(478);
        background: rgba(189, 203, 240, 0.1);
        backdrop-filter: blur(39px);
    }
}

.donate-confirm {

    &__payments {
        @include from($break_lg) {
            max-width: rem(729);
            margin-top: initial;
          min-height: rem(484);
        }
        margin-top: rem(24);
        width: 100%;
        height: 100%;
        background: rgba(77, 98, 151, 0.1);
        backdrop-filter: blur(39px);
    }
}
