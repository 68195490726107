.nav-main {
  position: relative;
  z-index: 10;
  top: 0;
  width: 100%;
  $navMainRoot: &;
  transition: .1s all;

  @include from($break_lg) {
  }

  &_active {
    //position: fixed;
    //background: rgba(34, 46, 75, 0.95);
    background: #222E4B;
  }

  .hamburger {
    @include from($break_lg) {
      display: none;
    }
  }

  &__items {
    @include from($break_lg) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }
    width: 100%;
    max-width: em(1720);
    margin: 0 auto;
    padding: em(32) em(16) 0 em(16);
  }

  &__item {
    @include from($break_lg) {
      display: inline-flex;
    }

    &_primary {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &_links {

      #{$navMainRoot}_active & {
        height: 100vh;
      }

      @include from($break_lg) {
        height: auto;
        overflow: initial;
      }
      height: 0;
      overflow: hidden;
      transition: .3s all;
    }
  }
}

.profile-menu {
  position: absolute;
  right: 0;
  bottom: rem(-160);
  background: #2F3C5A;
  border-radius: 20px;
  overflow: hidden;

  &__balance {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: rem(20) rem(23) rem(20) rem(23);
  }

  &__item {
    padding: rem(13) 0;
    background: #2F3C5A;
    text-align: center;
    cursor: pointer;
    transition: .3s all;

    span {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(19);
      transition: .3s all;
      text-transform: uppercase;
      color: #fff;
    }

    &:hover {
      //background: #445783;

      span {
        color: #BACCFB;
      }
    }
  }
}

.profile-balance {
  display: flex;
  flex-flow: column nowrap;

  &__about {
    font-weight: 500;
    font-size: rem(12);
    line-height: rem(14);
    color: #7F8AAF;
  }

  span {
    font-weight: 700;
    font-size: rem(12);
    line-height: rem(14);
    color: #FFF;
  }
}

.nav-links {

  &__items {
    position: relative;
    @include from($break_lg) {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  &__item {
    margin-top: em(24);

    &:last-of-type {
      margin-right: initial;
    }
  }

  &__link {
    @include from($break_xl) {
      font-size: rem(16);
    }
    position: relative;
    font-weight: 500;
    line-height: rem(24);
    font-size: rem(14);
    text-transform: uppercase;
    text-shadow: 0 3px 4px rgba(0, 0, 0, 0.14);
    padding: em(16);
    cursor: pointer;
    color: #fff;

    &_login {
      display: inline-flex;
      align-items: center;
      border: 3px solid #fff;
      padding: em(9) em(20) em(9) em(24);
      border-radius: em(32);

      i {
        margin-left: em(9);
      }
    }

    &::before, &::after {
      content: "";
      position: absolute;
      width: em(14);
      height: em(14);
      transition: all 0.35s ease;
      opacity: 0;
    }

    &::before {
      top: 0;
      left: 0;
      border-left: 3px solid #fff;
      border-top: 3px solid #fff;
      -webkit-transform: translate(100%, 50%);
      transform: translate(100%, 50%);
    }

    &::after {
      right: 0;
      bottom: 0;
      border-right: 3px solid #fff;
      border-bottom: 3px solid #fff;
      -webkit-transform: translate(-100%, -50%);
      transform: translate(-100%, -50%);
    }

    &:not(&_login) {

      &:hover {

        &::before, &::after {
          transform: translate(0%, 0%);
          opacity: 1;
        }
      }
    }
  }
}

.logo {
  @include from($break_xl) {
    width: em(277);
    height: em(64);
  }
  width: em(277 * 0.65);
  height: em(64 * 0.65);
  background: url("../../images/logo.svg") no-repeat top center / 100%;
}
