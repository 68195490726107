@import "../../../assets/css/vendor/smart-grid";
@import "../../../assets/css/helpers/functions";

.donate-payments {
  position: relative;
  padding: rem(25) rem(25) rem(26) rem(41);

  &__header {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    //flex-flow: row nowrap;
    //align-items: center;

    @include from($break_sm) {
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  &__or {
    display: block;
    margin-bottom: rem(18);

    font-weight: 500;
    font-size: rem(18);
    text-align: center;
    color: #fff;

    @include from($break_sm) {
      margin-right: rem(10);
    }
  }

  &__title {
    font-weight: 700;
    font-size: rem(18);
    line-height: rem(22);
    text-transform: uppercase;
    color: #fff;
    margin-bottom: rem(17);

    //width: 100%;
    text-align: center;

    @include from($break_sm) {
      margin-right: auto;
      text-align: left;
    }
  }

  &__activate {
    //margin-top: em(16);
    width: 100%;
    border: initial;
    padding: rem(12) rem(24);

    font-weight: 700;
    font-size: rem(18);
    line-height: rem(22);
    text-transform: uppercase;

    color: #fff;

    margin-bottom: rem(17);

    background: linear-gradient(90deg, #FFC600 0%, #FF4D00 100%);
    border-radius: rem(2);
  }

  &__activate {

  }

  &__alert {
    position: absolute;
    right: rem(6);
    top: rem(27);
    font-size: rem(10);
    color: #fff;
    max-width: rem(300);
    width: 100%;
  }

  &__root {
  }

  &__container {
    @include from(400px) {
      grid-template-columns: 1fr 1fr;
    }

    @include from(768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;
    grid-auto-rows: 79px;
    padding-right: rem(32);
  }
}

.simplebar-track.simplebar-vertical {
  width: 7px !important;
  background: rgba(164, 189, 255, 0.1) !important;
  backdrop-filter: blur(39px) !important;
}

.simplebar-scrollbar.simplebar-visible {
  &::before {
    width: 7px;
    left: 0;
    background: rgba(185, 205, 255, 0.24);
    backdrop-filter: blur(39px);
  }
}
