@import "../../../../../assets/css/vendor/smart-grid";
@import "../../../../../assets/css/helpers/functions";

.home-server {
  @include col();
  @include from(1600px) {
    max-width: em(300);
  }
  @include size(3);
  @include size-xl(4);
  @include size-lg(4);
  @include size-md(6);
  @include size-sm(12);
  @include size-xs(12);
  position: relative;
  margin-top: em(60);
  $serverItemRoot: &;

  &__online {
    position: absolute;
    bottom: em(6);
    left: em(17);
    font-weight: 500;
    font-size: rem(18);
    line-height: rem(22);
    color: #FFF;

    p {
      font-size: rem(14);
      margin-left: rem(4);
    }

    span {
      position: relative;
      display: inline-flex;
      align-items: center;

      &::before {
        content: ' ';
        display: inline-flex;
        width: rem(13);
        height: rem(13);
        border-radius: 50%;
        margin-right: em(7);
      }
    }

    &_engaged {

      span {

        &::before {
          background: #AEFF47;
        }
      }
    }

    &_disengaged {

      span {

        &::before {
          background: #FFF;
        }
      }
    }
  }

  .online-bar {
    position: relative;
    min-height: em(40);
    background: #242424;
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);

    &__value {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-weight: 500;
      font-size: rem(18);
      line-height: rem(22);
      color: #fff;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 30%;
      background: linear-gradient(90deg, #FFC600 0%, #FF4D00 100%);
      box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
    }
  }

  &:nth-of-type(2) .online-bar__bg {
    background: linear-gradient(90deg, #EE008F 0%, #FF0000 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &:nth-of-type(3) .online-bar__bg {
    background: linear-gradient(90deg, #00C2FF 0%, #0066FF 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &:nth-of-type(4) .online-bar__bg {
    background: linear-gradient(90deg, #8000FF 0%, #BD00FF 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &:nth-of-type(5) .online-bar__bg {
    background: linear-gradient(90deg, #FF4D00 0%, #FF0000 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &:nth-of-type(6) .online-bar__bg {
    background: linear-gradient(90deg, #FFC600 0%, #FF1569 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &:nth-of-type(7) .online-bar__bg {
    background: linear-gradient(90deg, #00EEB5 0%, #BD00FF 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &:nth-of-type(8) .online-bar__bg {
    background: linear-gradient(90deg, #8F00FF 0%, #4200FF 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &:nth-of-type(9) .online-bar__bg {
    background: linear-gradient(90deg, #B7D8FF 0%, #636CBA 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }

  &:nth-of-type(10) .online-bar__bg {
    background: linear-gradient(90deg, #00FF57 0%, #008575 100%);
    box-shadow: 0px -2px 43px rgba(1, 0, 70, 0.25);
  }
}

.players-inOrder {
  font-size: rem(14);
}
